import styles from './transfer-done.module.scss';
import MainButton from '../../../button/MainButton';
import {ReactComponent as BoltGreen} from '../../../../assets/svg/bolt-green.svg';
import {ReactComponent as CheckMarkIcon} from '../../../../assets/svg/check-mark.svg';
import {formatPrice} from '../../../../utils/utils';
import {useState} from 'react';

const TransferDone = ({ submitTx, provider, targetInfo, valueInfo, canProceed = false }) => {
	const [disabled, setDisabled] = useState(false);

	const handleSubmit = () => {
		setDisabled(true);
		submitTx();
	};

	return <div className={styles.container}>
		<div className={styles.topContainer}>
			<div className={styles.heading}>
				Transfer
			</div>

			<div className={styles.infoLineContainer}>
				<div className={styles.iconContainer}>
					<div className={styles.icon}>
						<CheckMarkIcon/>
					</div>
				</div>
				<div className={styles.textContainer}>
					You approve the transfer from <span className={styles.bold}>{provider.description}</span>{' '}
					to <span className={styles.bold}>{targetInfo.description}</span>
				</div>
			</div>

			<div className={styles.infoLineContainer}>
				<div className={styles.iconContainer}>
					<div className={styles.icon}>
						<CheckMarkIcon/>
					</div>
				</div>
				<div className={styles.textContainer}>
					Transfer of <span className={styles.bold}>{valueInfo?.value.toFixed(6)} {valueInfo.currency}</span>{' '}
					from {provider.description} to {targetInfo.description}
				</div>
			</div>
		</div>

		<div className={styles.bottomContainer}>
			<div className={styles.infoSummary}>
				You will receive <span className={styles.bold}>{valueInfo?.value.toFixed(6)} {valueInfo.currency}
					{' '}≈{' '}
					{formatPrice(valueInfo.converted_value, valueInfo.converted_currency, 2)}</span>{' '}
					in {targetInfo.description}
			</div>
			<div className={styles.detailedExplanation}>
				Transfers are not complete until they receive enough network confirmations.{' '}
				Your transfer{'\''}s estimated arrival time is about 5 minutes.{' '}
				This may vary depending on the network, network congestion, miner supply, transaction fees, and other factors.
			</div>
			<MainButton
				className='confirm-button'
				onClick={handleSubmit}
				label='Done'
				icon={<BoltGreen className='bolt-icon'/>}
				disabled={!canProceed || disabled}
			/>
		</div>
	</div>;
};

export default TransferDone;