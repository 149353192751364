import styles from './preview.module.scss';
import MainButton from '../../../button/MainButton';
import {ReactComponent as BoltGreen} from '../../../../assets/svg/bolt-green.svg';
// import {ReactComponent as EyeIcon} from '../../../../assets/svg/eye.svg';
import {formatPrice, valueExists} from '../../../../utils/utils';
import {useEffect, useMemo, useState} from 'react';
import Loading from '../../../status/loading';
import ErrorModalScreen from '../../shared/common/ErrorModalScreen';

const Preview = ({ provider, targetInfo, availableAccounts, fiatAmount, fiatCurrency, setExchangeAccountId, setValueInfo, handleNextStep, canProceed, loading, error }) => {
	const [localExchangeAccountId, setLocalExchangeAccountId] = useState();
	const localExchangeAccount = useMemo(
		() => valueExists(localExchangeAccountId) ? availableAccounts[localExchangeAccountId] : null,
		[localExchangeAccountId]
	);
	const exchangeRate = useMemo(
		() => localExchangeAccount?.value === 0 ? 0 : fiatAmount / localExchangeAccount?.value,
		[fiatAmount, localExchangeAccountId]
	);

	useEffect(() => {
		const keys = Object.keys(availableAccounts ?? {});
		if (keys.length > 0) {
			setLocalExchangeAccountId(keys[0]);
		}
	}, [availableAccounts]);

	const handleExchangeAccountChange = (e) => {
		setLocalExchangeAccountId(e.target.value);
	};

	const handleSubmit = () => {
		setValueInfo({
			value: localExchangeAccount?.value,
			currency: localExchangeAccount?.currency_name,
			converted_value: fiatAmount,
			converted_currency: fiatCurrency,
		});
		setExchangeAccountId(localExchangeAccountId);
		handleNextStep();
	};

	if (loading) {
		return <div className={styles.loadingContainer}>
			<Loading />
		</div>;
	}

	if (valueExists(error)) {
		return <ErrorModalScreen message={error} />;
	}

	return <div className={styles.container}>
		<div className={styles.topSection}>
			<div className={styles.heading}>
				Preview
			</div>
			<div className={styles.summaryContainer}>

				<div className={styles.summaryLineContainer}>
					<div className={styles.rowTitle}>
					From
					</div>
					<div className={styles.providerContainer}>
						<div className={styles.iconContainer}>
							<img src={provider?.icon} alt={provider?.description}/>
						</div>
						<div>
							{provider?.description}
						</div>
					</div>
				</div>
				<div className={styles.divider}></div>

				<div className={styles.summaryLineContainer}>
					<div className={styles.rowTitle}>
						To
					</div>
					<div className={styles.providerContainer}>
						<div className={styles.iconContainer}>
							<img src={targetInfo?.icon} alt={targetInfo?.description}/>
						</div>
						<div>
							{targetInfo?.description}
						</div>
						{/*<div>*/}
						{/*	({shortenString(targetInfo?.address, 12)})*/}
						{/*</div>*/}
						{/*<div className={styles.purpleIcon}>*/}
						{/*	<EyeIcon/>*/}
						{/*</div>*/}
					</div>
				</div>
				<div className={styles.divider}></div>

				{/*<div className={styles.summaryLineContainer}>*/}
				{/*	<div className={styles.rowTitle}>*/}
				{/*		Network*/}
				{/*	</div>*/}
				{/*	<div>*/}
				{/*		{network}*/}
				{/*	</div>*/}
				{/*</div>*/}
				{/*<div className={styles.divider}></div>*/}

				<div className={styles.summaryLineContainer}>
					<div className={styles.rowTitle}>
						Funding method
					</div>
					<div className={styles.radioOptions}>
						{Object.entries(availableAccounts ?? {}).map(([accountId, value]) => <div className={styles.optionContainer} key={accountId}>
							<input
								type='radio'
								name='balance-radios'
								value={accountId}
								checked={localExchangeAccountId === accountId}
								onChange={handleExchangeAccountChange}
							/>
							<div>{value.currency} Balance</div>
						</div>)}
					</div>
				</div>
				<div className={styles.divider}></div>

				<div className={styles.totalLineContainer}>
					<div>
						Total
					</div>
					<div>
						{localExchangeAccount?.value.toFixed(6)} {localExchangeAccount?.currency_name} ≈ {formatPrice(fiatAmount, fiatCurrency, 2)}
					</div>
				</div>
			</div>
		</div>

		<div className={styles.bottomSection}>
			<div className={styles.feesHeading}>
				All fees included
			</div>
			<div className={styles.feeInfoLineContainer}>
				<div>
					{localExchangeAccount?.value.toFixed(6)} {localExchangeAccount?.currency_name} @ {formatPrice(exchangeRate, fiatCurrency, 2)}
				</div>
				<div>
					{formatPrice(fiatAmount, fiatCurrency, 2)}
				</div>
			</div>

			<div className={styles.feeInfoLineContainer}>
				{/*<div>*/}
				{/*	Estimated gas fee*/}
				{/*</div>*/}
				{/*<div>*/}
				{/*	{formatPrice(42.16, fiatCurrency, 2)}*/}
				{/*</div>*/}
			</div>
			<MainButton
				className='confirm-button'
				onClick={handleSubmit}
				label='Proceed'
				icon={<BoltGreen className='bolt-icon' />}
				disabled={!canProceed}
			/>
		</div>
	</div>;
};

export default Preview;