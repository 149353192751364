import AppRoutes from './routes/AppRoutes';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';
import { useConfig } from './components/config/config';


const App = () => {

	// Global Web3Modal setup
	const {getConfig} = useConfig();
	const projectId = getConfig('walletConnectProjectId');
	const mainnet = {
		chainId: 1,
		name: 'Ethereum',
		currency: 'ETH',
		explorerUrl: 'https://etherscan.io',
		rpcUrl: 'https://cloudflare-eth.com',
	};
	const metadata = {
		name: 'Blockmate.io',
		description: 'Blockmate',
		url: 'https://blockmate.io',
		icons: [],  // TODO add avatar
	};
	const ethersConfig = defaultConfig({
		metadata,
		/*Optional*/
		enableCoinbase: false,  // If you remove this, it will work on PC, but the modal won't open on Android
		defaultChainId: 1, // used for the Coinbase SDK
	});
	createWeb3Modal({
		ethersConfig,
		chains: [mainnet],
		projectId,
	});
	
	return <AppRoutes/>;
};

export default App;