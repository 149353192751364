import {useEffect, useState} from 'react';
import './style.scss';
import ModalHeader from '../modal-header/ModalHeader';
import Loading from '../../status/loading';
import ConnectionMessage from '../../status/ConnectionMessage';
import BlockmateLogoBlack from '../../../assets/svg/blockmate-logo-black.svg';
import useApiInstances from '../../../services/api-call/axios';
import { useWeb3Modal } from '@web3modal/ethers/react';
import { useWeb3ModalAccount, useDisconnect } from '@web3modal/ethers/react';

const WalletConnect = ({token, provider, onCancel, handleClose, handleBackArrow}) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const [response, setResponse] = useState('');
	const [connectedAddress, setConnectedAddress] = useState();
	const { address, isConnected } = useWeb3ModalAccount();
	const { open } = useWeb3Modal();
	const { disconnect } = useDisconnect();
	const { apiLink } = useApiInstances();

	const handleConnect = ({ form, provider, token, onSuccess, onError }) => {
		const controller = new AbortController();

		const sendInputsData = async () => {
			try {
				const resp = await apiLink.post('/account',
					{
						...form,
						'account_url': provider.url
					},
					{
						signal: controller.signal,
						params: { 'jwt': token },
						headers: { 'content-type': 'application/json' }
					}
				);

				if (resp.status === 200) {
					onSuccess();
				}
			} catch (error) {
				onError(error);
			}
		};

		sendInputsData().catch(console.error);

		return () => {
			controller.abort();
		};
	};

	useEffect(() => {
		const performInit = async () => {
			if (isConnected) {
				await disconnect();
			}
			await open();
		};
		performInit().then(() => setIsLoaded(true));
	}, []);

	useEffect(() => {
		if (isLoaded && isConnected && address) {
			setConnectedAddress(address);
			handleConnect({
				form: {
					description: address,
					wallet: address,
					verified: true
				},
				provider: {
					...provider,
					url: `onchain/${provider.name}`
				},
				token,
				onSuccess: () => {
					setResponse('success');
				},
				onError: (error) => {
					setResponse(error?.response?.data?.detail ?? error.message);
				}
			});
			disconnect();
		}
	}, [address, isConnected, isLoaded]);

	return <>
		<ModalHeader
			handleClose={() => handleClose(response)}
			withArrow
			handleBackArrow={handleBackArrow}
			providerLogo={BlockmateLogoBlack}
		/>
		{!connectedAddress && <Loading/>}
		{connectedAddress && <ConnectionMessage
			response={response}
			account={connectedAddress}
			closeAction={() => handleClose(response)}
		/>}
	</>;
};


export default WalletConnect;