const appRoutes = {
	home: {
		modal: '/',
		connectAccount: '/connect-account',
		verifyPhone: '/verify-phone',
		changePhone: '/change-phone',
		enableTransfer: '/enable-transfer',
		transferAssets: '/transfer-assets',
		cryptoSavings: '/crypto-savings',
		withdrawAssets: '/withdraw-assets',
		depositFromExchange: '/deposit-exchange',
		depositFromWalletConnect: '/deposit-wallet-connect',
	},
	external: {
		termsAndCondition: 'https://www.blockmate.io/term/terms-and-conditions',
		documentation: 'https://docs.blockmate.io/docs/link'
	},
};

export default appRoutes;
