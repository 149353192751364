import {handleClose} from 'blockmate-react-link';
import ModalHeader from '../../modal-components/modal-header/ModalHeader';
import {useEffect, useMemo, useState} from 'react';
import DepositIntro from '../shared/deposit-intro/DepositIntro';
import {useSearchParams} from 'react-router-dom';
import ConnectWallet from './connect-wallet/ConnectWallet';
import DirectDepositDone from './direct-deposit-done/DirectDepositDone';
import BlockmateLogoBlack from '../../../assets/svg/blockmate-logo-black.svg';
import FinalMessage from '../shared/final-message/FinalMessage';
import useDirectDeposit from '../../../services/api-call/directDeposit';


const STEPS = {
	depositIntro: 0,
	connectAndSubmit: 1,
	depositDone: 2,
};

const DepositFromWalletConnect = () => {
	const [step, setStep] = useState(STEPS.depositIntro);
	const [searchParams, _] = useSearchParams();
	const targetInfo = useMemo(() => {
		const description = searchParams.get('merchantDescription');
		const icon = searchParams.get('merchantIcon');
		return {
			description,
			icon,
		};
	}, [searchParams]);
	const [merchantSupportedCurrencies, setMerchantSupportedCurrencies] = useState([]);
	const [cryptoAmount, setCryptoAmount] = useState();
	const [depositAddress, setDepositAddress] = useState();
	const [authChallenge, setAuthChallenge] = useState();
	const [error, setError] = useState('');
	const depositId = useMemo(() => searchParams.get('depositId'), [searchParams]);
	const jwt = useMemo(() => searchParams.get('jwt'), [searchParams]);
	const {overview, authorize, finish} = useDirectDeposit(jwt);

	const authorizeDeposit = async (address, signature, currency) => {
		try {
			const data = await authorize(depositId, address, signature, currency);
			setDepositAddress(data.depositAddress);
			setCryptoAmount(data.cryptoAmount);
		} catch (err) {
			const detail = String(err?.response?.data?.detail);
			setError(`Error authorizing deposit: ${detail}`);
			return false;
		}
		return true;
	};

	const finishDeposit = async (transactionId) => {
		try {
			await finish(depositId, transactionId);
		} catch (err) {
			setError('Error finishing deposit.');
		}
	};

	useEffect(() => {
		overview(depositId)
			.then(data => {
				setAuthChallenge(data.authChallenge);
				setMerchantSupportedCurrencies(data.supportedCurrencies);
			}).catch(err => {
				const detail = String(err?.response?.data?.detail);
				setError(`Error fetching deposit: ${detail}`);
			});
	}, []);

	const handleNextStep = () => {
		if (step < STEPS.depositDone) {
			setStep(prev => prev + 1);
		}
	};

	const handleCloseWithEndResult = () => {
		if (step === STEPS.depositDone) {
			handleClose('depositSuccess');
		} else {
			handleClose();
		}
	};

	if (error) {
		return <div className='modal-container'>
			<div className='modal-content'>
				<ModalHeader
					handleBackArrow={() => {}}
					handleClose={() => handleClose()}
					providerLogo={BlockmateLogoBlack}
					withArrow={false}
				/>
				<FinalMessage message={error} handleClose={handleClose} withCounter={false} />
			</div>
		</div>;
	}

	return <div className='modal-container'>
		<div className='modal-content'>
			<ModalHeader
				handleBackArrow={() => {}}
				handleClose={handleCloseWithEndResult}
				providerLogo={BlockmateLogoBlack}
				withArrow={false}
			/>
			{step === STEPS.depositIntro && (
				<DepositIntro
					targetInfo={targetInfo}
					handleNextStep={handleNextStep}
					isLoading={false}
					canProceed={!error}
				/>
			)}
			{step === STEPS.connectAndSubmit && (
				<ConnectWallet
					authorizeDeposit={authorizeDeposit}
					finishDeposit={finishDeposit}
					depositAddress={depositAddress}
					merchantSupportedCurrencies={merchantSupportedCurrencies}
					cryptoAmount={cryptoAmount}
					authChallenge={authChallenge}
					handleNextStep={handleNextStep}
					setError={setError}
				/>
			)}
			{step === STEPS.depositDone && (
				<DirectDepositDone handleClose={handleCloseWithEndResult} />
			)}
		</div>
	</div>;
};

export default DepositFromWalletConnect;
