import './style.scss';
import MainButton from '../../button/MainButton';
import {ReactComponent as ExitIcon} from '../../../assets/svg/exit-icon.svg';
import {ReactComponent as ArrowBack} from '../../../assets/svg/arrow-back-icon.svg';
import {useKeyPress} from '../../../hooks/useKeyPress';

const ModalHeader = ({handleBackArrow, handleClose, providerLogo, children, withArrow}) => {

	useKeyPress({
		Escape: handleBackArrow
	});

	return (
		<div className='modal-header'>
			<MainButton
				disabled={!withArrow}
				onClick={handleBackArrow}
				className='icon-button'
				icon={<ArrowBack/>}
			/>
			<div className='provider-logo'>
				<img src={providerLogo} alt=''/>
				{children}
			</div>
			<MainButton
				onClick={handleClose}
				className='icon-button'
				icon={<ExitIcon/>}
			/>
		</div>
	);
};

export default ModalHeader;
