import styles from './connect-wallet.module.scss';
import {ReactComponent as LayersIcon} from '../../../../assets/svg/layers-general.svg';
import {ReactComponent as WalletConnectIcon} from '../../../../assets/svg/wallet-connect.svg';
import {ReactComponent as PrivacyGuardIcon} from '../../../../assets/svg/privacy-guard-icon.svg';
import {ReactComponent as EyeIcon} from '../../../../assets/svg/eye.svg';
import {ReactComponent as InfoIcon} from '../../../../assets/svg/info-icon.svg';
import MainButton from '../../../button/MainButton';
import {useEffect, useMemo, useState} from 'react';
import {shortenString} from '../../../../utils/utils';
import {useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider, useDisconnect, useWeb3ModalState} from '@web3modal/ethers/react';
import {BrowserProvider, Contract, parseUnits} from 'ethers';
import {USDT_CONTRACT_ADDRESS, USDT_ABI} from '../../../../utils/contract-utils/usdt';
import Select from '../../../select/Select';

const WEI_IN_ETH = 10 ** 18;

const STAGES = {
	selectCurrency: 0,
	init: 1,
	connecting: 2,
	authorizing: 3,
	requestingTxSignature: 4,
};

const DISABLED_BUTTON_LABEL_BY_STAGE = {
	[STAGES.connecting]: 'Connecting',
	[STAGES.authorizing]: 'Waiting for signature',
	[STAGES.requestingTxSignature]: 'Requesting signature',
};

const ENABLED_BUTTON_LABEL_BY_STAGE = {
	[STAGES.selectCurrency]: 'Continue',
	[STAGES.init]: 'Connect wallet',
	[STAGES.connecting]: 'Connect wallet',
	[STAGES.authorizing]: 'Sign challenge',
	[STAGES.requestingTxSignature]: 'Sign transaction',
};

const FRONTEND_SUPPORTED_CURRENCIES = ['ETH', 'USDT'];

const ConnectWallet = ({ depositAddress, cryptoAmount, merchantSupportedCurrencies, authChallenge, authorizeDeposit, finishDeposit, handleNextStep, setError }) => {
	const [stage, setStage] = useState(STAGES.selectCurrency);
	const [txHash, setTxHash] = useState();
	const supportedCurrencies = useMemo(
		() => merchantSupportedCurrencies.filter(merchantCurrency => FRONTEND_SUPPORTED_CURRENCIES.includes(merchantCurrency)),
		[merchantSupportedCurrencies]
	);
	const currencyOptions = useMemo(() => supportedCurrencies.map(currency => ({currency})), [supportedCurrencies]);
	const [cryptoCurrency, setCryptoCurrency] = useState(supportedCurrencies?.[0]);
	const { address, isConnected } = useWeb3ModalAccount();
	const { open } = useWeb3Modal();
	const { open: isOpen } = useWeb3ModalState();
	const { walletProvider } = useWeb3ModalProvider();
	const { disconnect } = useDisconnect();
	const buttonDisabled = useMemo(() => {
		if (stage <= STAGES.init) {
			return false;
		}
		if (stage === STAGES.connecting) {
			return isOpen;
		}
		return true;
	}, [stage, isOpen]);

	const onConnect = async () => {
		setError(null);
		if (isConnected) {
			await disconnect();
		}
		await open();
		setStage(STAGES.connecting);
	};

	const performAuth = async () => {
		let challengeSignature;
		try {
			setStage(STAGES.authorizing);
			const provider = new BrowserProvider(walletProvider);
			const signer = await provider.getSigner();
			const authChallengeUtf8 = Buffer.from(authChallenge, 'hex').toString('utf8');
			challengeSignature = await signer?.signMessage(authChallengeUtf8);
		} catch (err) {
			setError('Failed to sign the challenge.');
			await disconnect();
			return;
		}
		try {
			await authorizeDeposit(address, challengeSignature, cryptoCurrency);
			setStage(STAGES.requestingTxSignature);
		} catch (err) {
			const detail = String(err?.response?.data?.detail);
			setError(`Failed to authorize: ${detail}`);
			await disconnect();
		}
	};

	const buildAndSubmitTx = async (token = 'eth') => {
		const provider = new BrowserProvider(walletProvider);
		const signer = await provider.getSigner();

		let txId, txInfo;
		const tokenLower = token.toLowerCase();
		try {
			if (tokenLower === 'usdt') {
				const token = new Contract(USDT_CONTRACT_ADDRESS, USDT_ABI, signer);
				const amount = parseUnits(String(cryptoAmount.toFixed(2)), 6);  // 6 is appropriate for USDT
				txInfo = await token.transfer(depositAddress, amount);
			} else if (tokenLower === 'eth') {
				const tx = {
					from: address,
					to: depositAddress,
					value: `0x${(Math.ceil(cryptoAmount * WEI_IN_ETH)).toString(16)}`,
					data: '0x',
				};
				txInfo = await signer.sendTransaction(tx);
			} else {
				setError(`Unsupported token name: ${token}`);
			}
			txId = txInfo;
			if (txInfo?.hash) {
				txId = txInfo.hash;
			}
		} catch (err) {
			console.error(err);
			setError('Failed to submit the transaction. Please start a new deposit process.');
			await disconnect();
			return;
		}
		try {
			await finishDeposit(txId);
			await disconnect();
			setTxHash(txId);
		} catch (err) {
			setError(
				`The deposit was sent but not registered by our server. Please contact support and share the transaction id: ${txId}`
			);
			await disconnect();
		}
	};

	useEffect(() => {
		if (address && stage === STAGES.connecting) {
			performAuth();
		}
	}, [address, stage]);

	useEffect(() => {
		if (stage === STAGES.requestingTxSignature) {
			buildAndSubmitTx(cryptoCurrency);
		}
	}, [stage]);

	useEffect(() => {
		if (txHash) {
			handleNextStep();
		}
	}, [txHash]);

	return <div className={styles.container}>
		{stage === STAGES.selectCurrency && (
			<div className={styles.topPart}>
				<div className={styles.title}>
					Select currency
				</div>
				<div className={styles.logo}>
					<LayersIcon/>
				</div>
				<div className={styles.description}>
					Please select a currency that you would like to perform the deposit in.
				</div>
				<Select
					options={currencyOptions}
					keySelector='currency'
					onOptionClick={option => setCryptoCurrency(option.currency)}
					value={cryptoCurrency}
				/>
			</div>
		)}
		{stage === STAGES.init && (
			<div className={styles.topPart}>
				<div className={styles.title}>
				Connect your wallet
				</div>
				<div className={styles.logo}>
					<WalletConnectIcon/>
				</div>
				<div className={styles.description}>
					Please connect your wallet to proceed with the deposit.
				</div>
				<div className={styles.infoEntry}>
					<div className={styles.infoIcon}>
						<InfoIcon />
					</div>
					<div className={styles.text}>
						To connect your wallet, push the button below and scan the QR code with your wallet app
						afterwards.
					</div>
				</div>
			</div>
		)}
		{stage === STAGES.connecting && (
			<div className={styles.topPart}>
				<div className={styles.title}>
					Connect your wallet
				</div>
				<div className={styles.logo}>
					<WalletConnectIcon/>
				</div>
				<div className={styles.description}>
					Please wait until the connection is established.
				</div>
			</div>
		)}
		{stage === STAGES.authorizing && (
			<div className={styles.topPart}>
				<div className={styles.title}>
					Prove ownership
				</div>
				<div className={styles.logo}>
					<PrivacyGuardIcon/>
				</div>
				<div className={styles.description}>
					Please sign the challenge in your wallet
					to prove the ownership of <span className={styles.address}>{shortenString(address ?? '', 12)}</span>.
				</div>
				<div className={styles.infoEntry}>
					<div className={styles.infoIcon}>
						<InfoIcon/>
					</div>
					<div className={styles.text}>
						The challenge should appear in your wallet automatically.
					</div>
				</div>
				<div className={styles.infoEntry}>
					<div className={styles.infoIcon}>
						<InfoIcon/>
					</div>
					<div className={styles.text}>
						There are no fees involved for this operation.
					</div>
				</div>
			</div>
		)}
		{stage === STAGES.requestingTxSignature && (
			<div className={styles.topPart}>
				<div className={styles.title}>
					Review and sign
				</div>
				<div className={styles.logo}>
					<EyeIcon/>
				</div>
				<div className={styles.description}>
					Review the deposit transaction in your wallet
					and sign it afterwards.
				</div>
				<div className={styles.infoEntry}>
					<div className={styles.infoIcon}>
						<InfoIcon />
					</div>
					<div className={styles.text}>
						If you do not see the signature request in your wallet,
						wait a bit more please.
					</div>
				</div>
			</div>
		)}
		<div className={styles.bottomPart}>
			<MainButton
				className='confirm-button'
				onClick={onConnect}
				disabled={buttonDisabled}
				icon={<WalletConnectIcon className='wallet-connect-icon'/>}
				label={buttonDisabled ? DISABLED_BUTTON_LABEL_BY_STAGE[stage] : ENABLED_BUTTON_LABEL_BY_STAGE[stage]}
			/>
		</div>
	</div>;
};

export default ConnectWallet;
